.loading-container {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: rgb(229, 232, 237);
  background-color: "#FFFFFF";
}

.Mlogoss {
  width: 61px;
  height: 22px;
  top: -10px;
  user-select: none;
}
.sweet {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-size: 300% 300%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 5px 7px rgba(0, 0, 0, 0.25);
  animation: gradient 3s ease infinite, rotate 2s linear infinite;
  transform: perspective(1000px) rotateX(30deg); /* 3D effect for the sweet */
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes rotate {
  0% {
    transform: perspective(1000px) rotateY(0deg);
  }
  100% {
    transform: perspective(1000px) rotateY(360deg);
  }
}

.loading-text {
  margin-top: 10px;
}
